import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import BodyDiagram from "../../components/template-partials/about/bodydiagram"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import VideoInfoCard from "../../components/template-partials/global-components/video-infocard"
import AboutSubmenu from "../../components/template-partials/about/about-submenu"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/1.0_backgroundheader.jpg"
import headingRound from "../../images/about-and-research-nav-image.jpg"
import eightHundCases from "../../images/800-new-cases.jpg";
import beforeFiveYears from "../../images/under-five-years.jpg";
import oneToTwo from "../../images/one-to-two-years-old.jpg";
import sixPercent from "../../images/six-percent-of-all-cases.jpg";
import videoScreen from "../../images/tvicon_yellow.png";
import neuroblasts from "../../images/1.1-neuroblast.jpg";

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const WhatIsPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="What is Neuroblastoma | Causes & Symptoms" description="Researching neuroblastoma can be an overwhelming experience. Start your research journey here with answers to some of the most important questions. Learn about neuroblastoma causes, signs, symptoms, and more." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="What is Neuroblastoma?">
        <h1 className="section__heading section__heading--green h1">What <em>is</em> Neuroblastoma?</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`About Neuroblastoma &amp; Research`}>
              <AboutSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">What is neuroblastoma–causes and symptoms</h2>
                <p>When starting to research neuroblastoma, you may feel overwhelmed by the amount of information out there. It's normal to want to learn everything you can to help your child. Here are some questions and answers to help get you started:</p>
                <ul className="ul--dotted">
                  <li>What is neuroblastoma?</li>
                  <li>Who gets neuroblastoma?</li>
                  <li>What are the signs and symptoms of neuroblastoma?</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns small-12 large-4 text-center">
            <FancyImage url={neuroblasts} alt="Illustration of neuroblasts"/>
          </div>
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">What is neuroblastoma?</h3>
            <p>Neuroblastoma is a childhood cancer that starts in immature nerve cells (<Link rel="glossary" to="/support-and-community/glossary/#neuroblasts">neuroblasts</Link>). The term “neuro” refers to nerves, and “blastoma” refers to a tumor of immature or developing cells.</p>
            <ul className="ul--dotted">
              <li>Neuroblastoma tumors can occur anywhere in the body, but about 65% of them occur in the abdomen</li>
              <li>Neuroblastoma tumors are unique. Some may go away on their own without treatment, while others may spread (<Link rel="glossary" to="/support-and-community/glossary/#metastasize">metastasize</Link>) throughout the body</li>
            </ul>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Who gets neuroblastoma?</h3>
            <p>Neuroblastoma is the most common cancer in infants under the age of 1.</p>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
          <Slider className="grid-slider" {...settings}>
            <div className="text-center">
              <img src={eightHundCases} alt="The US reports about 800 new neuroblastoma cases each year" />
              <p>Every year there are about 800 new cases of neuroblastoma in the US</p>
            </div>
            <div className="text-center">
              <img src={beforeFiveYears} alt="Most cases of neuroblastoma are diagnosed before age 5"/>
              <p>Most neuroblastoma cases are diagnosed before the age of 5</p>
            </div>
            <div className="text-center">
              <img src={oneToTwo} alt="Neuroblastoma diagnoses typically occur at 1 to 2 years old"/>
              <p>The average age of a child at diagnosis is 1 to 2 years old</p>
            </div>
            <div className="text-center">
              <img src={sixPercent} alt="Neuroblastoma makes up about 6% of all childhood cancer cases"/>
              <p>Neuroblastoma accounts for about 6% of all childhood cancer cases</p>
            </div>
          </Slider>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">What are the signs and symptoms of neuroblastoma?</h3>
            <p>Neuroblastoma is typically discovered when the growing tumor starts to cause symptoms. The signs and symptoms of neuroblastoma can vary based on: </p>
            <ul className="ul--dotted">
              <li>Where the tumor is</li>
              <li>How large the tumor is</li>
              <li>Whether or not the neuroblastoma has spread (<Link rel="glossary" to="/support-and-community/glossary/#metastasize">metastasized</Link>) to other areas of the body</li>
            </ul>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns text-center">
            <h4 className="h4 tc-viridian jost-med lg_mt-2">If a child has a tumor that:</h4>
            <div className="lg_mt-2 mt-2">
              <BodyDiagram alt="is on the spine, they may stop crawling or walking or have weakness or paralysis. If a child has a tumor that is in the chest, they may have trouble breathing. If a child has a tumor that is in the abdomen, they may have pain, an enlarged belly, constipation, vomiting, diarrhea, difficulty urinating, or high blood pressure. If a child has a tumor that has spread (metastasized) to the bone, or to other areas of the body, they may have black eyes, bone pain, bruises, fever, or paleness, and may limp or stop crawling or walking." />
              <div className="visually-hidden">
                <p><strong>is on the spine</strong>, they may stop crawling or walking or have weakness or paralysis</p>
                <p><strong>is in the chest</strong>, they may have trouble breathing</p>
                <p><strong>is in the abdomen</strong>, they may have pain, an enlarged belly, constipation, vomiting, diarrhea, difficulty urinating, or high blood pressure</p>
                <p><strong>has spread (metastasized) to the bone, or to other areas of the body</strong>, they may have black eyes, bone pain, bruises, fever, or paleness, and may limp or stop crawling/walking</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard topic="How is Neuroblastoma Diagnosed?" title="How is neuroblastoma diagnosed?" lead="Learn about the tests and scans used to diagnose neuroblastoma." url="/what-is-neuroblastoma/how-is-it-diagnosed/" />
      </InteriorPageFooter>

    </Layout>
  )
}

export default WhatIsPage
